'use client';

import React, { useCallback } from 'react';
import { cn, isValidDate, setTime } from '@jucy-ui/common';
import { useController, useFormContext } from '@jucy-ui/forms';
import { DateRange } from '@mui/x-date-pickers-pro';
import { isBefore, isToday } from 'date-fns';
import { TripDateRangePicker, TripDateRangePickerProps } from '../../fields';
import { SearchFormDatePickerPaperDesktop, SearchFormDatePickerPaperMobile, SearchFormPickersPopper } from '../components';
import { useSearchFormStore } from '../hooks';
import { getDefaultDropOffTime, getDefaultPickUpTime, getMinPickUp, searchFormValuesToData } from '../lib';
import { jucySearchFormClasses } from '../lib/jucySearchFormClasses';
import { SearchFormValues } from '../types';

const tripFormFieldsSx = {
    mt: 1,
};
export const SearchFormDateRangePicker: React.FC<TripDateRangePickerProps<Date>> = ({ children, ...props }) => {
    const store = useSearchFormStore();
    const {
        field: { onChange: onPickUpDateChanged, ...pickUpDateField },
    } = useController({ name: 'pickUpDate' });
    const {
        field: { onChange: onDropOffDateChanged, ...dropOffDateField },
    } = useController({ name: 'dropOffDate' });
    const { getValues, setValue, clearErrors } = useFormContext<SearchFormValues>();
    const handleDateRangeChange = useCallback(
        (dateRange: DateRange<Date> | undefined) => {
            const { pickUpTime, dropOffTime, pickUpBranch, dropOffBranch, fleetType } = searchFormValuesToData(getValues());
            const isPickUpToday = dateRange?.[0] && isToday(dateRange[0]);
            const minPickUpToday =
                isPickUpToday && dateRange[0] && pickUpBranch && fleetType
                    ? getMinPickUp({ date: dateRange[0], branch: pickUpBranch, fleetType })
                    : undefined;
            const pickUpDate = isValidDate(dateRange?.[0])
                ? isValidDate(pickUpTime) && isPickUpToday && minPickUpToday && !isBefore(dateRange[0], minPickUpToday)
                    ? setTime(dateRange[0], pickUpTime)
                    : getDefaultPickUpTime({ date: dateRange[0], branch: pickUpBranch, fleetType })
                : null;
            const dropOffDate = isValidDate(dateRange?.[1])
                ? isValidDate(dropOffTime)
                    ? setTime(dateRange[1], dropOffTime)
                    : getDefaultDropOffTime({ date: dateRange[1], branch: dropOffBranch })
                : null;
            onPickUpDateChanged(pickUpDate);
            onDropOffDateChanged(dropOffDate);
        },
        [clearErrors, getValues, onDropOffDateChanged, onPickUpDateChanged, setValue]
    );
    return (
        <TripDateRangePicker
            {...props}
            sx={tripFormFieldsSx}
            slots={{
                desktopPaper: SearchFormDatePickerPaperDesktop,
                mobilePaper: SearchFormDatePickerPaperMobile,
                popper: SearchFormPickersPopper,
            }}
            localeText={{
                start: pickUpDateField.label,
                end: dropOffDateField.label,
            }}
            onClose={() => {
                if (store.focusedDateField) {
                    dropOffDateField.onBlur();
                }
                store.focusedDateField = undefined;
            }}
            value={[pickUpDateField.value || null, dropOffDateField.value || null]}
            onChange={handleDateRangeChange}
            slotProps={{
                popper: {
                    onBlur: () => {
                        pickUpDateField.onBlur();
                    },
                },
                textField: ({ position }) => ({
                    onFocus: () => {
                        store.focusedDateField = position;
                    },
                    className: cn(
                        jucySearchFormClasses.dateSelect,
                        position === 'start' ? jucySearchFormClasses.pickUpDate : jucySearchFormClasses.dropOffDate
                    ),
                    name: (position === 'start' ? pickUpDateField : dropOffDateField).name,
                    error: (position === 'start' ? pickUpDateField : dropOffDateField).error,
                    helperText: (position === 'start' ? pickUpDateField : dropOffDateField).helperText,
                }),
            }}
        >
            {children}
        </TripDateRangePicker>
    );
};
