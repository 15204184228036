import { addDays } from 'date-fns';
import { jucyAttributionStore } from './jucyAttributionStore';
import { cookies } from './jucyCookies';

const paramsToPreserve = [
    'cjevent',
    'cjdata',
    'cfclick',
    'utm_source',
    'utm_medium',
    'utm_content',
    'clientId',
    'gclid',
    'sessionId',
    'tduid',
    'kayakclickid',
    'affiliateId',
    'fbclid',
    'awc',
    'sn',
];

const affiliateParams = [
    { param: 'cjevent', expireInDays: 30 },
    { param: 'cjdata', expireInDays: 30 },
    { param: 'cfclick', expireInDays: 30 },
    { param: 'tduid', expireInDays: 30 },
    { param: 'kayakclickid', expireInDays: 30 },
    { param: 'affiliateId', expireInDays: 30 },
    { param: 'awc', expireInDays: 30 },
    { param: 'sn', expireInDays: 30 },
];

const getAlternateAffiliateParams = (param: string) => {
    if (param.startsWith('cj')) {
        return affiliateParams.filter((p) => !p.param.startsWith('cj'));
    }
    if (param.startsWith('cf')) {
        return affiliateParams.filter((p) => !p.param.startsWith('cf'));
    }
    if (['awc', 'sn'].includes(param)) {
        return affiliateParams.filter((p) => !['awc', 'sn'].includes(p.param));
    }
    return affiliateParams.filter((p) => p.param !== param);
};

const getAffiliateParam = (param: string) => affiliateParams.find((p) => p.param === param);

const isServer = typeof window === 'undefined';
class JucyAttribution {
    params = paramsToPreserve;

    init() {
        if (isServer) {
            return;
        }
        for (const [param, value] of this.getFromSearchParams()) {
            if (!value) {
                continue;
            }
            const affiliateParam = getAffiliateParam(param);
            if (affiliateParam) {
                const paramsToRemove = getAlternateAffiliateParams(affiliateParam.param);
                for (const toRemove of paramsToRemove) {
                    jucyAttributionStore.removeItem(toRemove.param);
                }
            }
            const expireInDays = affiliateParam?.expireInDays || 365;
            const expires = addDays(new Date(), expireInDays);
            jucyAttributionStore.set({ param, value, expires });
        }

        const url = new URL(window.location.href);
        const gaLinker = url.searchParams.get('_gl');
        if (gaLinker) {
            cookies.set('_gl', gaLinker);
        }
        this.autoDecorateLinks();
    }

    getFromSearchParams() {
        const result = new Map<string, string>();
        if (isServer) {
            return result;
        }
        const url = new URL(window.location.href);
        for (const param of this.params) {
            for (const [key, value] of url.searchParams) {
                if (value && param.toLowerCase() === key.toLowerCase()) {
                    result.set(param, value);
                }
            }
        }
        return result;
    }

    getValue(param: string) {
        const result = jucyAttributionStore.get(param);
        return result?.value;
    }

    getFromStorage() {
        const result = new Map<string, string>();
        if (isServer) {
            return result;
        }
        for (const value of jucyAttributionStore.getAll()) {
            result.set(value.param, value.value);
        }

        return result;
    }

    getForwardUrlSearchParams() {
        if (isServer) {
            return new URLSearchParams();
        }
        const attributionParams = this.getFromStorage();
        const searchParams = new URLSearchParams(Object.fromEntries(attributionParams));

        for (const cookieParam of ['_gl', '_fbc', '_fbp']) {
            const value = cookies.get(cookieParam);
            if (value) {
                searchParams.set(cookieParam, value);
            }
        }
        return searchParams;
    }

    private isCrossDomainLink(href?: string | null): href is string {
        if (!href || isServer) {
            return false;
        }

        try {
            const url = new URL(href);
            if (url.hostname === window.location.hostname) {
                return false;
            }
        } catch (e) {
            //
        }

        return href.startsWith('http') && ['jucy.com', 'starrv.com'].some((domain) => href.includes(domain));
    }

    private autoDecorateLinks() {
        if (isServer) {
            return;
        }
        const links = document.querySelectorAll<HTMLAnchorElement>('a');
        for (const link of links) {
            if (!this.isCrossDomainLink(link.getAttribute('href'))) {
                continue;
            }
            this.decorateLink(link);
        }

        const observer = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                if (mutation.type === 'childList') {
                    for (const node of mutation.addedNodes) {
                        if (node instanceof HTMLAnchorElement) {
                            this.decorateLink(node);
                            break;
                        }
                    }
                } else if (mutation.type === 'attributes' && mutation.target instanceof HTMLAnchorElement) {
                    this.decorateLink(mutation.target);
                }
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
            attributes: true,
            attributeFilter: ['href'],
        });
    }

    decorateLink(link: HTMLAnchorElement) {
        const href = link.getAttribute('href');
        if (!this.isCrossDomainLink(href)) {
            return;
        }
        const decoratedHref = this.decorateUrl(href);
        if (href === decoratedHref) {
            return;
        }
        link.setAttribute('href', decoratedHref);
    }

    decorateUrl(href: string) {
        if (isServer) {
            return href;
        }
        try {
            const url = new URL(href);
            for (const [key, value] of this.getForwardUrlSearchParams()) {
                if (url.searchParams.has(key)) {
                    continue;
                }
                url.searchParams.append(key, value);
            }
            return url.toString();
        } catch (_e) {
            return href;
        }
    }
}

export const jucyAttribution = new JucyAttribution();
