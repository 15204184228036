import Cookies, { CookieSetOptions } from 'universal-cookie';

const isServer = typeof window === 'undefined';
const getCookieDomain = () => {
    if (isServer) {
        return undefined;
    }
    if (window.location.hostname.includes('jucy')) {
        return '.jucy.com';
    }
    if (window.location.hostname.includes('starrv')) {
        return '.starrv.com';
    }

    const isDev = /^(127|localhost)/.test(window.location.hostname);
    if (isDev) {
        return 'localhost';
    }
    return undefined;
};
const cookieDomain = getCookieDomain();
class JucyCookies extends Cookies {
    set(name: string, value: unknown, options?: CookieSetOptions): void {
        super.set(name, value, {
            domain: cookieDomain,
            path: '/',
            secure: cookieDomain !== 'localhost',
            httpOnly: false,
            sameSite: false,
            ...options,
        });
    }
}

export const cookies = new JucyCookies();
