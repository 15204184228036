'use client';

import { useEffect } from 'react';
import { setAwinAdvertiserConsent } from './AwinMasterTag';

export const CookieConsent = () => {
    useEffect(() => {
        const controller = new AbortController();
        window.addEventListener(
            'CookiebotOnAccept',
            () => {
                setAwinAdvertiserConsent(true);
            },
            { signal: controller.signal }
        );
        window.addEventListener(
            'CookiebotOnDecline',
            () => {
                setAwinAdvertiserConsent(false);
            },
            { signal: controller.signal }
        );
        return () => {
            controller.abort();
        };
    }, []);

    return null;
};

declare global {
    interface Window {
        Cookiebot?: Cookiebot;
    }
}

interface Cookiebot {
    consent: {
        necessary: boolean;
        preferences: boolean;
        statistics: boolean;
        marketing: boolean;
        method: string | null;
    };
    consented: boolean;
    declined: boolean;
    hasResponse: boolean;
    doNotTrack: boolean;
    regulations: {
        gdprApplies: boolean;
        ccpaApplies: boolean;
        lgpdApplies: boolean;
    };

    show(): void;

    hide(): void;

    renew(): void;

    getScript(url: string, async?: boolean, callback?: () => void): void;

    runScripts(): void;

    withdraw(): void;

    submitCustomConsent(optinPreferences: boolean, optinStatistics: boolean, optinMarketing: boolean): void;
}
