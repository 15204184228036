import { addDays } from 'date-fns';
import { cookies } from './jucyCookies';

type StoredAttributionParam = {
    param: string;
    value: string;
    expires: Date;
};

export const jucyAttributionStore = {
    persist: (value: StoredAttributionParam[]) => {
        cookies.set('jac', value, { expires: addDays(new Date(), 365) });
    },
    set: (value: StoredAttributionParam) => {
        const data = jucyAttributionStore.getAll().filter((d) => d.param !== value.param);
        data.push(value);
        jucyAttributionStore.persist(data);
    },
    getAll: (): StoredAttributionParam[] => {
        try {
            const data = cookies.get<StoredAttributionParam[]>('jac') || [];
            return data
                .map((d) => ({
                    ...d,
                    expires: new Date(d.expires),
                }))
                .filter((d) => d.expires > new Date());
        } catch (e) {
            return [];
        }
    },
    get: (key: string): StoredAttributionParam | undefined => jucyAttributionStore.getAll().find((d) => d.param === key),
    removeItem: (key: string) => {
        const data = jucyAttributionStore.getAll().filter((d) => d.param !== key);
        jucyAttributionStore.persist(data);
    },
};
