import { UserMode } from '#/types/UserMode';
import Quote from '../../api/model/Quote';
import { SalesMonitorProvider } from './SalesMonitorProvider';
import { getCommissionableTotal } from '#/lib/SalesMonitoring/getCommissionableTotal';
import ErrorReporter from '#/lib/ErrorReporter';
import { asError } from '#/lib/asError';
import BusinessUnitsStore from '#/store/BusinessUnitsStore';
import { countries } from '@jucy/rentals-common';
import { jucyAttribution } from '#/lib/SalesMonitoring/jucyAttribution';

export class Awin implements SalesMonitorProvider {
    confirmPurchase(quote: Quote): void {
        if (!window.AWIN?.Tracking) {
            return;
        }
        try {
            const { total, discount } = getCommissionableTotal(quote);
            const customerCountry = quote.HirerDetails?.DriversLicenceCountry ? countries.find((c) => c.name === quote.HirerDetails?.DriversLicenceCountry) : null;
            window.AWIN.Tracking.Sale = {};
            window.AWIN.Tracking.Sale.amount = `${total}`;
            window.AWIN.Tracking.Sale.channel = 'aw';
            window.AWIN.Tracking.Sale.orderRef = quote.ReservationReference;
            window.AWIN.Tracking.Sale.parts = `DEFAULT:${total}`;
            window.AWIN.Tracking.Sale.currency = quote.TotalPrice.CurrencyCode;
            window.AWIN.Tracking.Sale.voucher = quote.FleetCategory.campaignCode;
            window.AWIN.Tracking.Sale.test = '0';
            window.AWIN.Tracking.Sale.custom = [
                quote.PickUpSite?.CountryCode as string,
                quote.FleetCategory.CategoryCode,
                quote.PickUpLocationCode,
                quote.DropOffLocationCode,
                quote.PickUpDateTime.toISOString(),
                quote.DropOffDateDateTime.toISOString(),
                quote.FleetCategory.fleetType?.slug || 'unknown',
                BusinessUnitsStore.getByQuote(quote)?.name || 'unknown',
                customerCountry?.code || '',
                `${discount || ''}`,
            ];
            window.AWIN.Tracking.run?.();
        } catch (e) {
            ErrorReporter.reportError({ error: asError(e), level: 'error', tags: { source: 'CommissionJunction', reservationReference: quote.ReservationReference } });
        }
    }

    enabled(quote: Quote, accountType: UserMode): boolean {
        return Boolean(accountType === 'direct' && quote?.BookingStatus === 'Confirmed' && window.AWIN?.Tracking && jucyAttribution.getValue('awc') && jucyAttribution.getValue('sn'));
    }
}
