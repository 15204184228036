import Script from 'next/script';

export const AwinMasterTag = ({ awinId }: { awinId?: string }) => {
    if (!awinId) {
        return null;
    }
    return (
        <Script
            id="awin-master-tag"
            src={`https://www.dwin1.com/${awinId}.js`}
            strategy="afterInteractive"
            onReady={() => {
                if (typeof window.Cookiebot?.consent?.marketing !== 'undefined') {
                    setAwinAdvertiserConsent(window.Cookiebot.consent.marketing);
                }
            }}
        />
    );
};

let awinConsentSet = false;
export const setAwinAdvertiserConsent = (status: boolean) => {
    if (awinConsentSet) {
        return;
    }

    awinConsentSet = true;
    if (window.AWIN?.Tracking?.Consent) {
        window.AWIN.Tracking.Consent.setAdvertiserConsentStatus(status);
    } else {
        window.AWIN = window.AWIN || {};
        window.AWIN.Tracking = window.AWIN.Tracking || {};
        window.AWIN.Tracking.AdvertiserConsent = status;
    }
};

declare global {
    interface Window {
        AWIN?: {
            Tracking?: {
                Consent?: {
                    setAdvertiserConsentStatus: (status: boolean) => void;
                };
                AdvertiserConsent?: boolean;
                run?: () => void;
                Sale?: Partial<{
                    amount: string;
                    channel: string;
                    orderRef: string;
                    parts: string;
                    currency: string;
                    voucher: string;
                    test: string;
                    custom?: string[];
                }>;
            };
        };
    }
}
